'use strict';

Marquee3k.init();
document.addEventListener( 'DOMContentLoaded', function() {
  var splide = new Splide( '.splide',{
    mediaQuery: 'min',
    pagination: true,
      arrows: true,
    
    gap: '5rem',
    flickPower: 200,
    perPage: 1,
    breakpoints: {
      1200: {
        destroy: true,
      }
    }
  } );
  splide.mount();
} );



 const accordion = new Accordion('.accordion-container');
//close accordion pressing on content
 // const accContainer = document.querySelector('.accordion-container');
// const buttons = accContainer.querySelectorAll('.ac-panel');
// [...buttons].map((button, idx) => {
//   console.log(button);
//     button.addEventListener('click', () => accordion.close(idx));
// });

// валидация всех полей с цифрами
Maska.create('.masked');

// валидация телефона
const forms = document.querySelectorAll(".form-registr"),
    inputsPhone = document.querySelectorAll(".tel");


function inputValue(){
    if(this.value.substr(0,1) === "8" || this.value.substr(0,1) === "7"){
        this.value = this.value.replace(this.value.substr(0,1), '+7 (');
    }
}

inputsPhone.forEach((e)=>{
    e.addEventListener('input', inputValue);
    let mask = Maska.create(e, {
        mask: '+7 (###) ###-##-##'
    });
});

const btnForm = document.querySelector('#registration-form');
function submitFormLead(e) {
  let formDatas = new FormData(e);
  //вывод данных в консоль
  console.log(formDatas.get('name'));
  console.log(formDatas.get('email'));
  console.log(formDatas.get('phone'));
  console.log(formDatas.get('text'));

  btnForm.innerHTML = 'Заявка отправлена';
  btnForm.setAttribute('disabled', true);
 
  let xhr = new XMLHttpRequest();
  
  xhr.open("POST", "/sendemail"); // тут путь отправки данных


  xhr.send(formDatas);
  xhr.onload = function() {
     
      if (xhr.status == 200) {

          MicroModal.show('modal-success',{
              onShow:()=> document.body.classList.add("prevent-scroll"),
              onClose: ()=> document.body.classList.remove("prevent-scroll")
          });
        
      } 
  };
}
forms.forEach((e)=>{
  //валидация формы
  let pristine = new Pristine(e);
  e.addEventListener('submit', function (localForm) {
      localForm.preventDefault();
      let isValid = pristine.validate();
      if(isValid){
          submitFormLead(e);
      } else {
          return false
      }
  });
});